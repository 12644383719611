import NavButton from "./navButton"

function OneColumnCentered({ title, description, buttonText, buttonUrl, emoji, image, mobileTitle="" }) {
    return (
        // the outer div is the container for the entire component
        <div className="w-full h-full">
            {/* This div controls the centering of the text elements on the screen */}
            <div className="flex flex-col justify-center items-center h-full mt-36 md:mt-0 md:pb-24 text-white font-sans">
                <div className="w-full p-3 md:p-0 md:w-4/5 lg:w-2/3 flex justify-center flex-wrap">
                    {image && <div className="w-full flex justify-center mb-8">{image}</div>}
                    <h1 className="hidden md:flex text-lg md:text-xl lg:text-2xl font-bold font-sans mb-2 gap-x-4 text-center items-center">{title} {emoji}</h1>
                    <h1 className="flex md:hidden text-xl md:text-xl lg:text-2xl font-bold font-sans mb-2 gap-x-4 text-center items-center">{mobileTitle} {emoji}</h1>
                    <p className="text-md font-sans font-light mb-8 text-center w-full">{description}</p>
                    <div className="md:flex hidden"><NavButton url={buttonUrl} className="mt-4">{buttonText}</NavButton></div>
                    <div className="md:hidden flex w-full px-12"><NavButton url={buttonUrl} className="mt-4" size="large">{buttonText}</NavButton></div>
                </div>
            </div>
        </div>
    )
}

export default OneColumnCentered